@layer base {
  body {
    font-size: 13px !important;
    @apply bg-gray-100
  }
  // ----- scroll-bar UI-----------------------
  /* width */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }
  //-----------------------------------------------

  a {
    @apply text-blue-600 cursor-pointer hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150;

    &:hover {
      @apply text-blue-500;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;

    &:disabled {
      @apply select-none opacity-50;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='month'],
  [type='search'],
  [type='time'],
  [type='code'],
  [type='datetime-local'],
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    //font-size: 1rem;
    @apply leading-5 px-2 py-1 border border-gray-300 rounded max-w-lg shadow-sm focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500;
  }

  select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .tw-setting-layout {
    @apply h-auto mx-auto bg-white shadow overflow-hidden;
  }

  .tw-setting-wrapper {
    @apply divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x py-6;

    .setting-nav-wrapper {
      @apply md:col-span-2 lg:col-span-2;
    }

    .setting-content {
      @apply divide-y divide-gray-200 md:col-span-10 lg:col-span-10;
    }

    nav.tw-setting-nav {
      @apply flex-1 bg-white font-semibold;

      a {
        @apply w-full flex items-center pl-2 pr-1 py-2 text-gray-500 group-hover:text-gray-900 hover:bg-gray-300 focus:outline-none;
      }

      a.is-active {
        @apply text-gray-900 bg-gray-300 border-l-4 border-blue-500;
      }
      a.is-active.has-sub-nav {
        @apply text-gray-500 bg-gray-200 border-none;
      }

      .sub-nav {
        @apply bg-gray-200;

        a {
          @apply w-full flex items-center bg-transparent pl-5 pr-2 py-2 text-gray-500 group-hover:text-gray-900 hover:bg-gray-300;
        }

        a.is-active {
          @apply text-gray-900 bg-gray-300 border-l-4 border-blue-500;
        }
      }
    }
  }
}


.tw-shim {
  position: relative;
  overflow: hidden;
  background-color: rgb(222, 231, 251);
  @apply relative w-full overflow-hidden h-2 mb-4 text-xs flex rounded
}
.tw-shim::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 3s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.tw-progress-bar {
  top: 0%;
  right: 0%;
  bottom: 100%;
  left: 0%;
  z-index: 10000;
  @apply fixed;
  .completed {
    @apply shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500
  }
}

