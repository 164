@layer base {
  .flex-center {
    @apply flex items-center;
  }

  .tw-tag {
    @apply w-max h-auto text-gray-800 bg-gray-300 inline-flex items-center mr-1 rounded-md px-2;

    &.tw-tag-xs {
      @apply text-xs;
      padding: 2px 5px;
    }

    &.tw-tag-secondary {
      @apply bg-gray-300;
    }

    &.tw-tag-primary {
      @apply bg-blue-500 text-white;
    }

    &.tw-tag-danger {
      @apply bg-red-200 text-red-800;
    }

    &.tw-tag-success {
      @apply bg-green-100 text-green-800;
    }
  }

  .tw-tag.has-addons {
    span.tw-tag-name {
      @apply text-white inline-flex hover:bg-blue-600 items-center px-2.5 py-0.5 bg-blue-500 rounded-l;
    }
    span.tw-tag-danger {
      @apply text-white inline-flex hover:bg-red-600 items-center px-2.5 py-0.5 bg-red-500 rounded-l;
    }
    span.tw-tag-name-secondary {
      @apply text-white inline-flex hover:bg-blue-500 items-center px-2.5 py-0.5 bg-blue-600 rounded-l;
    }
    span.tw-tag-x {
      @apply text-gray-800 inline-flex hover:bg-red-400 items-center px-2.5 py-0.5 rounded-r font-medium bg-gray-200;
    }
  }

  .tw-autocomplete-highlight {
    @apply text-blue-600 font-medium;
  }

  .tw-file-uploader {
    @apply justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md;

    .tw-form-upload {
      @apply text-sm text-gray-600;

      label {
        @apply cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500;
      }

      input[type='file'] {
        @apply justify-center;
      }
    }
  }

  .tw-disabled {
    @apply select-none opacity-50 cursor-not-allowed;
  }

  .gallery-wrapper {
    text-align: left;

    .ngx-gallery {
      display: inline-block;
    }
  }

  .tw-tooltip-d3 {
    min-width: 60px;
    min-height: 28px;
    padding: 4px;
    background: lightsteelblue;
    @apply absolute text-center flex items-center rounded-lg pointer-events-none font-medium text-xs border-0;
  }
}
