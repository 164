@layer base {
  .tw-card.card-sm {
    @apply col-span-3
  }

  .tw-card.card-lg {
    @apply col-span-9
  }

  .tw-card {
    .card-title{
      @apply text-xl font-bold text-gray-900
    }
    .card-body {
      @apply p-3 bg-white rounded-lg shadow grid col-span-1 gap-1 mt-2
    }
  }

  .tw-page {
    .page-heading {
      @apply py-4 border-b border-gray-200 mb-1;

      h1 {
        @apply text-lg font-medium leading-6 text-gray-900;
      }
    }

    .page-title-xl {
      @apply text-2xl font-bold text-gray-900
    }

    .page-content {
      @apply top-4 p-3 bg-white rounded-lg shadow grid col-span-1 gap-1 mt-2;
    }

    .page-detail {
      @apply mx-auto py-2 grid grid-cols-12 gap-5;
    }

    nav.breadcrumb {
      @apply flex;

      ol {
        @apply flex items-center mt-3 mb-1;
      }

      ol li .breadcrumb-item {
        @apply flex items-center;
      }

      ol li .separator {
        @apply flex-shrink-0 h-5 w-5 text-gray-300;
      }

      ol li a {
        @apply text-sm font-medium text-gray-500 hover:text-gray-700;
      }

      ol li:last-child a {
        @apply flex items-center text-2xl font-bold text-gray-900;
      }
    }

    .contact {
      @apply flex justify-between items-center min-w-0;
      .contact-child {
        @apply flex flex-row space-x-6
      }
      .contact-content {
        @apply mt-2 flex items-center text-sm text-gray-500
      }
    }
  }
}
