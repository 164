@layer base {
  .tw-modal {
    @apply hidden fixed z-10 inset-0 overflow-y-auto;

    .tw-modal-wrapper {
      @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
    }

    .tw-modal-background {
      @apply fixed inset-0 transition-opacity;
    }

    .tw-modal-background .tw-modal-background-content {
      @apply absolute inset-0 bg-gray-700 opacity-50;
    }

    .tw-modal-trick {
      @apply hidden sm:inline-block sm:align-middle sm:h-screen;
    }

    .tw-modal-content-wrapper {
      @apply inline-block align-bottom bg-white pb-4 rounded-lg px-3 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle;

      .tw-modal-btn-close {
        @apply sm:block absolute top-0 right-0 pt-3 pr-3;

        & button {
          @apply bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500;
        }
      }

      .tw-modal-content {
        .tw-modal-content-icon {
          @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 mr-2;
        }

        .tw-modal-headline {
          @apply mt-3 text-lg leading-6 font-medium text-gray-900;
        }
      }

      .tw-modal-footer {
        @apply mt-5 sm:mt-4 sm:flex sm:flex-row-reverse;
      }
    }
  }

  .tw-modal.is-active {
    @apply block;
  }

  .tw-modal-overlay {
    @apply inline-block align-bottom bg-white pb-4 rounded px-3 text-left shadow-xl transform transition-all sm:align-middle; // remove overflow-hidden

    .tw-modal-overlay-btn-close {
      @apply sm:block absolute top-0 right-0 pt-3 pr-3;

      & button {
        @apply bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500;
      }
    }

    .tw-modal-overlay-content {
      .tw-modal-overlay-content-icon {
        @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 mr-2;
      }

      .tw-modal-overlay-headline {
        @apply mt-3 text-lg leading-6 font-medium text-gray-900 cursor-move;
      }
    }

    .tw-modal-overlay-footer {
      @apply mt-5 sm:mt-4 sm:flex sm:flex-row-reverse;
    }
  }

  .tw-modal-overlay-background {
    @apply bg-gray-700 bg-opacity-10;
  }
}
