@layer base {
  table.tw-table {
    @apply min-w-full divide-y divide-gray-200;
  }

  table.tw-table th {
    @apply px-3 py-2 bg-gray-50 text-left text-xs whitespace-nowrap text-gray-500 tracking-wider;

    &.actions-col {
      @apply text-right;
    }
  }

  table.tw-table {
    tbody,
    tfoot {
      @apply bg-white divide-y divide-gray-200 text-gray-900;

      tr {
        &:hover {
          @apply bg-blue-100
        }
      }

      td {
        @apply px-3 py-2 whitespace-pre-line align-top;

        &.actions-col {
          @apply text-right w-28;
        }
      }
    }

  }

  @mixin arrow {
    content: '';
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
    float: right;
  }

  th {
    &.asc {
      &::after {
        @include arrow();
      }
    }

    &.desc::after {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      @include arrow();
    }

    .tw-is-invalid {
      @apply leading-5 px-2 py-1 border border-gray-300 rounded max-w-lg block w-full shadow-sm focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-red-500 focus:border-red-500;
    }

    .tw-is-invalid {
      @apply leading-5 px-2 py-1 border border-gray-300 rounded max-w-lg block w-full shadow-sm focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-red-500 focus:border-red-500;
    }
  }

  .no-pagination .tw-pagination {
    display: none;
  }

  .tw-pager,
  .tw-pagination {
    @apply bg-white text-sm px-1 py-2 flex items-center justify-between border-t border-gray-200 text-gray-700;

    .total-records,
    .hint-wrapper {
      @apply sm:flex-1 sm:flex sm:items-center sm:justify-between;
    }

    .tools-wrapper {
      @apply sm:flex-1 sm:flex sm:items-end justify-end;

      select {
        @apply min-w-0 w-20 ml-2 mr-2;
      }
    }

    .page-size select {
      @apply min-w-0 w-20 ml-2 mr-2;
    }

    nav {
      @apply relative z-0 inline-flex shadow-sm -space-x-px;

      a {
        @apply relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
      }

      a:first-child {
        @apply px-2 rounded-l-md;
      }

      a.is-active {
        @apply bg-blue-500 text-white border-blue-600;
      }

      a:last-child {
        @apply px-2 rounded-r-md;
      }
    }

    ul.pages {
      @apply relative z-0 inline-flex shadow-sm -space-x-px;

      li a {
        @apply relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
      }

      li:first-child a {
        @apply rounded-l-md;
      }

      li.is-active a {
        @apply bg-blue-500 text-white border-blue-600;
      }

      li.disabled a {
        @apply disabled:opacity-50 text-gray-200 cursor-not-allowed;
      }

      li:last-child a {
        @apply rounded-r-md;
      }
    }
  }
}