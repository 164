@layer base {
  .tw-dropdown-panel {
    @apply absolute z-10 mt-1 rounded-md bg-white shadow-lg;

    ul {
      @apply max-h-80 rounded-md py-1 text-base ring-1 list-none ring-black ring-opacity-5 overflow-y-auto focus:outline-none sm:text-sm;

      li {
        @apply text-gray-900 cursor-default select-none relative py-1 px-2;

        input[type='checkbox'] {
          @apply h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded;
        }
      }
    }
  }

  .tw-dropdown {
    @apply relative inline-block text-left;
    z-index: 8;
    button {
      @apply inline-flex justify-center w-full rounded border border-gray-300 shadow-sm px-3 py-1 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500;
    }

    .dropdown-panel {
      @apply origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100;

      .dropdown-item {
        @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
      }
    }

    .dropdown-panel-left {
      @apply origin-top-left absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100;

      .dropdown-item {
        @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
      }
    }
    @media (max-width: 767px) {
      .dropdown-panel-left {
        @apply right-0;
      }
    }
  }
}
