@layer base {
  .tw-buttons {
    @apply flex space-x-1 mt-3;
  }

  .tw-buttons[disabled] {
    pointer-events: none;
  }

  .tw-btn-primary {
    @apply inline-flex justify-center leading-5 py-1 px-2 border border-transparent shadow-sm rounded text-white bg-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500;
  }

  .tw-btn-primary[disabled] {
    pointer-events: none;
  }

  .tw-btn-secondary {
    @apply inline-flex justify-center leading-5 py-1 px-2 border border-gray-300 bg-white text-gray-700 shadow-sm rounded hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-blue-500;
  }

  .tw-btn-secondary[disabled] {
    pointer-events: none;
  }

  .tw-btn-danger {
    @apply inline-flex justify-center leading-5 py-1 px-2 border border-transparent shadow-sm rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-red-500;
  }

  .tw-btn-danger[disabled] {
    pointer-events: none;
  }

  .tw-btn-dropdown {
    @apply relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-0 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
    line-height: 1.65rem;
  }

  .tw-btn-dropdown[disabled] {
    pointer-events: none;
  }

  .btn-following {
    @apply tw-btn-primary w-full;
    height: 2.15rem;
  }

  .btn-following[disabled] {
    pointer-events: none;
  }
}
