@layer base {
  .tw-top-nav {
    @apply relative lg:ml-6 md:ml-2 md:flex;

    a {
      @apply text-gray-500;
    }

    .root-item {
      @apply inline-flex items-center border-transparent text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out cursor-pointer;
    }
    
    .root-active {
      @apply text-blue-500 border-b-2 border-blue-500;
      > .flex-center-item > a {
        @apply text-blue-500;
      }
    }

    .sub-lv2-item {
      @apply absolute hidden top-2 left-full w-max bg-white rounded;
    }

    .sub-lv1 {
      @apply py-2 top-14 bg-white w-max hidden rounded;
    }

    .sub-content {
      @apply relative px-4 py-1;
    }

    .flex-center-item {
      @apply flex justify-between items-center;
    }

    .hover-item {
      a {
        @apply hover:text-blue-500;
      }
      @apply hover:bg-gray-50 hover:text-blue-500;
    }

    .menu-item-active {
      .item-active {
        @apply text-blue-500;
      }

      @apply text-blue-500 border-l-2 border-blue-500;
    }
  }
}
