@layer base {
  .tw-icon-4 {
    @apply inline h-4 w-4 mr-1;

    & .outline-border {
      @apply p-0.5 border border-gray-300 rounded-sm;
    }
  }

  .tw-icon-5 {
    @apply inline h-5 w-5;

    & .outline-border {
      @apply p-0.5 border border-gray-300 rounded-sm;
    }
  }

  .tw-icon-6 {
    @apply inline h-6 w-6;

    &.outline-border {
      @apply p-0.5 border border-gray-300 rounded-sm;
    }
  }

  .tw-icon-button {
    @apply bg-gray-100 hover:bg-gray-200 py-2 px-2 rounded inline-flex items-center mr-3;

    &.is-danger {
      @apply bg-red-600 text-white;
    }
    &.is-primary {
      @apply bg-blue-600 text-white;
    }
  }
}
