@layer base {
  .tw-bg-primary {
    @apply bg-blue-600;
  }

  .tw-bg-secondary {
    @apply bg-gray-500;
  }
  .tw-bg-success {
    @apply bg-green-500;
  }
  .tw-bg-danger {
    @apply bg-red-500;
  }
}
