@layer base {
  .tw-tabs {
    @apply flex justify-start;

    a {
      @apply border-transparent whitespace-nowrap p-2 border-b-2 text-gray-500 hover:text-blue-500 hover:border-blue-400;

      &.is-danger {
        @apply text-red-600 hover:text-red-500 hover:border-red-400;
      }

      &.is-active {
        @apply border-blue-500 text-blue-600;

        &.is-danger {
          @apply border-red-500 text-red-600;
        }
      }
    }
  }
}
