@layer base {
  .tw-autocomplete-wrapper {
    @apply relative w-4/5 mt-3;
    .icon-search {
      @apply absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none;
    }
    input.tw-autocomplete {
      @apply block w-full pl-10 pr-2 py-1 border border-gray-300 rounded leading-5 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
    }
  }

  .tw-form-group {
    > label,
    label.tw-form-label {
      @apply block text-gray-700 sm:mt-px sm:pt-2 mb-1;
    }

    input.invalid,
    select.invalid {
      @apply shadow appearance-none border border-red-500 focus:outline-none;
    }
    span.invalid {
      @apply flex-grow w-full text-red-500 text-xs;
    }

    & input.tw-form-control,
    & textarea.tw-form-control,
    & select.tw-form-control-select {
      @apply border-gray-300 block w-full;
    }
  }

  .tw-inline-form {
    @apply flex mt-1.5;
  }

  .tw-form-group.tw-inline-form {
    & label,
    label.tw-form-label,
    p.form-text {
      @apply flex-initial w-32 text-gray-700 sm:mt-px sm:pt-2;
    }

    p.form-text {
      @apply flex-initial w-auto text-gray-700 sm:mt-px sm:pt-2;
    }

    & input.tw-form-control,
    & textarea.tw-form-control,
    & select.tw-form-control-select {
      @apply flex flex-1;
    }
    // fixed with (w-full-label.w-32)
    & .min-w-full {
      min-width: calc(100% - 8rem) !important;
    }
  }

  .tw-float-form-group {
    @apply relative border-b-2 focus-within:border-blue-500;

    & input.tw-form-control {
      @apply block w-full appearance-none focus:outline-none bg-transparent;
    }

    & label {
      @apply absolute top-0 -z-1 duration-300 origin-0 text-gray-500;
    }
  }

  .tw-float-form-group input:focus-within ~ label,
  .tw-float-form-group input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-6;
  }

  .tw-float-form-group input:focus-within ~ label {
    @apply text-blue-500;
  }

  .tw-floating-input {
    @apply relative;
    input,
    select {
      @apply border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full px-2 pt-2 pb-0.5 h-9;
    }
    input::placeholder {
      color: transparent;
    }
    label {
      @apply absolute top-0 opacity-70 left-0 px-2 py-2 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out;
    }
    input:focus,
    input:not(:placeholder-shown) {
      @apply pt-2;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      @apply scale-90 -translate-y-2.5 translate-x-1;
    }

    .tw-form-control-ms {
      button {
        @apply border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full px-2 pt-2 pb-0.5 h-9;
      }
      label {
        position: relative;
        padding: 0;
      }
    }
  }
  .tw-floating-select {
    @apply relative;
    select {
      @apply border border-gray-300 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm w-full px-2 h-9;
    }
    label {
      @apply absolute top-0 opacity-70 left-0 px-3 pt-0 -mt-0.5 h-full;
      transform: scale(0.85);
    }
  }

  .tw-form.ng-invalid {
    input,
    select {
      &.ng-invalid.ng-touched {
        @apply shadow appearance-none border border-red-500 focus:outline-none;
      }
    }
    span.ng-invalid {
      @apply flex-grow w-full text-red-500 text-xs;
    }
  }
}
