@layer base {
  .tw-text-primary {
    @apply text-blue-600;
  }

  .tw-text-secondary {
    @apply text-gray-500;
  }

  .tw-text-danger {
    @apply text-red-500;
  }

  .tw-text-success {
    @apply text-green-500;
  }

  .tw-help-text {
    &.is-danger {
      @apply text-red-500;
    }

    &.is-success {
      @apply text-green-500;
    }
  }
}
